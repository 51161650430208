import { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton,
  Box,
  Card,
  Stack
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import useEmailVerifiedDialog from "features/2fa/useEmailVerifiedDialog";

const useStyles = makeStyles()(theme => ({
  wrapper: {
    minHeight: 405
  },
  welcomeBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.getContrastText(theme.palette.primary.lighter!)
  },
  tabs: {
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: theme.spacing(3)
  },
  tab: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1)
  },
  tabLabel: {
    fontWeight: theme.typography.fontWeightMedium
  },
  signUp: {
    borderBottom: theme.shape.getBorder(theme.palette.company.accent)
  },
  register: {
    borderBottom: theme.shape.border,
    textDecoration: "none"
  },
  form: {
    marginBottom: theme.spacing(2)
  },
  inputAdornment: {
    width: 35,
    justifyContent: "center"
  },
  icon: {
    color: theme.palette.common.black
  },
  helpLinksBox: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

interface LoginComponentProps {
  isLoading: boolean;
  loginAttempt: (email: string, password: string) => void;
  resendActivationEmail: (email: string) => void;
  openDialogWithProps: (dialogContentType: string, props: any) => void;
  showTechSupportNumber: boolean;
  techSupportNumber: string | undefined;
  helpUrl: string | undefined;
  emailRef: React.Ref<HTMLInputElement | null>;
}

const LoginComponent: React.FC<LoginComponentProps> = ({
  isLoading,
  loginAttempt,
  openDialogWithProps,
  showTechSupportNumber,
  techSupportNumber,
  helpUrl,
  emailRef
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { classes, cx } = useStyles();

  useEmailVerifiedDialog();

  function handleShowPasswordClick() {
    setShowPassword(!showPassword);
  }

  function handleSubmit(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    loginAttempt(email, password);
  }

  function handlePasswordResetClick() {
    openDialogWithProps("Password Reset", {});
  }

  return (
    <Card className={classes.wrapper}>
      <div className={classes.welcomeBox}>
        <Typography align="left" variant="subtitle1" color="inherit">
          Welcome!
        </Typography>
      </div>
      <Box p={2}>
        <div className={classes.tabs}>
          <div className={cx(classes.tab, classes.signUp)}>
            <Typography className={classes.tabLabel}>Sign In</Typography>
          </div>
          <Box className={cx(classes.tab, classes.register)} component={Link} to="/register">
            <Typography className={classes.tabLabel}>Register</Typography>
          </Box>
        </div>

        <form onSubmit={handleSubmit} className={classes.form}>
          <Stack spacing={2}>
            <div />

            <TextField
              id="adornment-email"
              label="Email"
              type="email"
              fullWidth
              value={email}
              onChange={evt => setEmail(evt.target.value)}
              InputLabelProps={{ shrink: true }}
              inputRef={emailRef}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.inputAdornment}>
                    <EmailIcon className={classes.icon} />
                  </InputAdornment>
                )
              }}
            />

            <TextField
              id="adornment-password"
              label="Password"
              type={showPassword ? "text" : "password"}
              fullWidth
              value={password}
              onChange={evt => setPassword(evt.target.value)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.inputAdornment}>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPasswordClick}
                      onMouseDown={evt => evt.preventDefault()} // not sure why this was needed.
                      edge="end"
                      size="small"
                    >
                      {showPassword ? (
                        <Visibility className={classes.icon} />
                      ) : (
                        <VisibilityOff className={classes.icon} />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <div />

            <Button type="submit" variant="contained" disabled={isLoading}>
              {isLoading ? <CircularProgress color="primary" size={20} /> : "Login"}
            </Button>
          </Stack>
        </form>

        <div className={classes.helpLinksBox}>
          <Typography
            color="secondary"
            onClick={handlePasswordResetClick}
            sx={{ cursor: "pointer" }}
          >
            Forgot your password?
          </Typography>

          {helpUrl && (
            <Typography
              color="secondary"
              component={Link}
              to="/help"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ textDecoration: "none" }}
            >
              Need Assistance?
            </Typography>
          )}
        </div>
        {!helpUrl && showTechSupportNumber && (
          <Typography align="left" color="secondary" sx={{ mt: 2 }}>
            Issues logging in? Contact support at {techSupportNumber}.
          </Typography>
        )}
      </Box>
    </Card>
  );
};

export default LoginComponent;
