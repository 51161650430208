import { parse } from "qs";
import { useLocation } from "react-router-dom";

export default function useParseSearchQueries(...keys) {
  const location = useLocation();

  const searchQueries = parse(location?.search ?? "", { ignoreQueryPrefix: true });

  return keys.map(key => searchQueries?.[key] ?? key);
}
