class JSONUtil {
  static getValueFromJSON(str: string, key: string) {
    if (!str) {
      return null;
    }
    let parsedStr = JSONUtil.parseJSON(str);
    if (!parsedStr) {
      return null;
    }
    const value = parsedStr[key];

    if (!value && typeof parsedStr === "string") {
      parsedStr = JSONUtil.parseJSON(parsedStr);
      return parsedStr ? parsedStr[key] : null;
    } else {
      return value;
    }
  }

  public static parseJSON(str: string) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return null;
    }
  }
}

export { JSONUtil as default };
