import { useEffect, useState } from "react";
import { alpha, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { createTermFilter } from "utils/SearchUtil";
import { showError } from "actions/notificationActions";
import { getMediasV2 as getMediasFromApi } from "api/mediaRequests";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { openDialogWithProps } from "actions/dialogActions";
import { useDispatch, useSelector } from "utils/StoreUtil";
import { loginAttempt } from "actions/userActions";
import { blue } from "@mui/material/colors";
import { setWelcomeScreenFlag } from "actions/appConfigActions";
import useEmailVerifiedDialog from "features/2fa/useEmailVerifiedDialog";

const useStyles = makeStyles()(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  logoWrapper: {
    minHeight: 280,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  logo: {
    maxWidth: 276
  },
  message: {
    color: "#465560",
    fontSize: 24,
    fontWeight: 400
  },
  arrow: {
    backgroundColor: theme.palette.primary.light,
    height: 45,
    width: "100%",
    clipPath: "polygon(0 40%, 0% 10%, 50% 70%, 110% 0, 140% 0, 50% 100%)",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  formHeader: {
    textAlign: "left",
    padding: theme.spacing(1),
    color: alpha(theme.palette.common.black, 0.6)
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%"
  },
  passwordIcon: {
    padding: "0 2px 0 1px",
    "& .MuiIconButton-label": {
      color: alpha(theme.palette.common.black, 0.87)
    }
  },
  userActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  button: {
    width: "100%",
    height: 56,
    marginTop: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  },
  formWrapper: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  },
  registerWrapper: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  textLink: {
    cursor: "pointer",
    textDecoration: "none",
    color: blue[800],
    "& visited": {
      color: blue[800]
    }
  }
}));

const MobileAppLogin = (): JSX.Element => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const companyId = useSelector((state: any) => state.user.user.companyId);
  const isLoading = useSelector((state: any) => state.user.isLoading);

  const imageName = "MobileLoginMedia";
  const localKey = "rememberMe";

  useEmailVerifiedDialog();

  const [email, setEmail] = useState(localStorage.getItem(localKey) ?? "");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(localStorage.getItem(localKey) !== null);
  const [logoUrl, setLogoUrl] = useState("");

  useEffect(
    function didMount() {
      dispatch(setWelcomeScreenFlag(true));

      async function fetchImages() {
        const filters = [
          createTermFilter("entityType", "COMPANY"),
          createTermFilter("companyId", companyId),
          createTermFilter("name", imageName)
        ];

        try {
          const { data } = await getMediasFromApi(filters, "items=0-0");

          if (data.length > 0) {
            let img = data.shift();
            if (img) setLogoUrl(`${img.urls.medium}`);
          } else {
            let baseUrl = process.env.PUBLIC_URL;
            setLogoUrl(`${baseUrl}/images/OnlineRingmanLogo.png`);
          }
        } catch (error) {
          showError((error as any).message);
        }
      }

      fetchImages();
    },
    [companyId, dispatch]
  );

  const onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (rememberMe) {
      localStorage.setItem(localKey, email);
    } else {
      localStorage.removeItem(localKey);
    }

    dispatch(loginAttempt(email, password));
  };

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item xs={12} className={classes.logoWrapper}>
        <img src={logoUrl} alt="" className={classes.logo} />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.arrow}></div>
      </Grid>

      <Grid item xs={12} className={classes.registerWrapper}>
        <form onSubmit={onSubmit}>
          <Grid container className={classes.formWrapper}>
            <Grid item xs={12}>
              <Typography color="initial" variant="subtitle1" className={classes.formHeader}>
                Sign in to your Account
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  id="adornment-email"
                  type="email"
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <EmailIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  label="Password"
                  fullWidth
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <IconButton
                          className={classes.passwordIcon}
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={event => event.preventDefault()}
                          edge="end"
                          size="small"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} className={classes.userActions}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                    name="checkedB"
                  />
                }
                label="Remember me"
              />

              <Typography
                className={classes.textLink}
                onClick={() => dispatch(openDialogWithProps("Password Reset", {}))}
              >
                Forgot your password?
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                className={classes.button}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress color="primary" size={20} /> : "Sign In"}
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12} className={cx(classes.formHeader, classes.registerWrapper)}>
            <span>Don't have an account? </span>
            <Link to="/register" className={classes.textLink}>
              {" "}
              Register{" "}
            </Link>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default MobileAppLogin;
