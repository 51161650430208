export const BACK_TO_TOP_ANCHOR = "back-to-top-anchor";
export const MAIN_CONTAINER_DIV = "mainContainer";

export const MARKET_SEGMENTS = {
  BLOODSTOCK: "bloodStock",
  AUTOMOTIVE: "automotive",
  CATTLE: "cattle",
  MERCHANDISE: "merchandise"
};

export const DIALOG_CONTENT_TYPE = {
  ADD_ADMIN: "Add Admin",
  ATTEND: "Attend",
  ATTEND_NEW: "Attend_NEW",
  BARCODE: "Barcode Scan",
  GET_SALE_USERS: "Get Sale Users",
  ATTEND_VIEW_ONLY: "Attend View Only",
  REGISTRATION_CONFIRMATION: "Registration Confirmation",
  UPDATE_PASSWORD: "Update Password",
  UPDATE_AUCTION_ACCESS: "Update Auction Access",
  CHECK_DEALERSHIPS: "Check Dealerships",
  CHECK_BADGES: "Check Badges",
  BID_CONTROLS: "Bid controls",
  NOTIFICATION_GROUP: "Notification Group",
  MEMBERSHIP_STATUS_UPDATE_CONFIRMATION: "Registration Status Update Confirmation",
  CREATE_MEMBERSHIPS: "Create Memberships",
  USER_DEALERSHIP_SYNC_MESSAGE: "User Dealership Sync Message",
  UPLOAD_USERS: "Upload Users",
  UPSERT_DEALERSHIP: "Upsert Dealership",
  ADMIN_BIDDER_INFO: "Bidder Info",
  ADMIN_NOTE: "Admin User Note",
  UPSERT_MEMBERSHIP_SCHEMA_FIELD: "Upsert Membership Schema Field",
  UPSERT_UI_SCHEMA_FIELD: "Upsert UI Schema Field",
  MEMBERSHIP_FIELDS: "Membership Fields",
  MEMBERSHIP_STATUS: "Membership Status",
  BIDDING_HELP: "Bidding Help",
  FLUSH_CONFIRMATION: "Flush Confirmation",
  RESEND_ACTIVATION_EMAIL: "Resend Activation Email",
  UPDATE_PERSONAL_INFO: "Update Personal Info",
  WARNING_DIFFERENT_FILES_UPLOADED: "Warning - Different File Uploaded",
  SHOW_ALL_CONSIGNORS: "Show all consignors",
  APPLICATION_MESSAGE: "Application Message",
  VERIFY_MOBILE_NUMBER: "Verify Mobile Number",
  UNSAVED_CHANGES_CONFIRMATION: "Unsaved Changes Confirmation",
  UNRESOLVED_PSI_REQUEST: "You are about to leave this page",
  UPLOAD_MEDIA: "Upload Media",
  SELL_TIMED_LOT: "Sell Timed Lot",
  MANAGE_COMMUNITY_DEALERSHIPS: "Manage Community Dealerships",
  LOGIN_PROMPT: "LOGIN_PROMPT",
  EVENT_PRINT: "Exporting Options",
  FLOORPLAN_ERRORS: "Floorplan errors",
  ADD_AAID: "ADD_AAID",
  UPSERT_MAX_BID: "UPSERT_MAX_BID",
  EMAIL_VERIFIED: "EMAIL_VERIFIED",
  REQUIRES_EMAIL_VERIFICATION: "REQUIRES_EMAIL_VERIFICATION",
  REQUIRES_TWO_FACTOR_VERIFICATION: "REQUIRES_TWO_FACTOR_VERIFICATION",
  VALIDATE_DEVICE_VERIFICATION_CODE: "VALIDATE_DEVICE_VERIFICATION_CODE",
  DEVICE_VERIFICATION_CODE_ERROR: "DEVICE_VERIFICATION_CODE_ERROR",
  TWO_FACTOR_VERIFICATION_SUCCESS: "TWO_FACTOR_VERIFICATION_SUCCESS"
};

export const DRAWER_CONTENT_TYPE = {
  ADMIN_INVENTORY_FILTERS: "Admin Inventory Filters",
  ADMIN_SALE_FILTERS: "Admin Sale Filters",
  ADMIN_ATTENDEE_FILTERS: "Admin Attendee Filters",
  ADMIN_CONSIGNOR_FILTERS: "Admin Consignor Filters",
  ADMIN_SALE_ACTION_FILTERS: "Admin Sale Action Log Filters"
};

export const UPLOAD_ACTIONS = {
  ADD: "ADD",
  WITHDRAW_AND_ADD: "WITHDRAW"
};

export const UPLOAD_STATUS = {
  UPLOADING: "UPLOADING",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED"
};

export const MEDIA_TYPE = {
  IMAGE_DOCUMENT: "IMAGE_DOCUMENT",
  IMAGE_PANORAMA: "IMAGE_PANORAMA",
  IMAGE_THREE_SIXTY: "IMAGE_THREE_SIXTY",
  IMAGE: "IMAGE",
  VIDEO: "VIDEO"
};

export const SALE_TYPE = {
  TIMED: "TIMED",
  TIMED_LEGACY: "TIMED_LEGACY",
  LIVE: "LIVE",
  LIVE_LEGACY: "LIVE_LEGACY"
};

export const USER_ACTIVITY_TYPE = {
  PURCHASE: "PURCHASE",
  BID: "BID"
};

export const PATHNAMES = {
  PURCHASE: "/home/activity/buyer/purchases",
  BID: "/home/activity/buyer/bids",
  OFFER: "/home/activity/buyer/offers"
};

export const BULK_ACTIONS_TYPE = {
  APPROVE_REGISTRATION_STATUS: "Approve Registration Status",
  REJECT_REGISTRATION_STATUS: "Reject Registration Status"
};

export const MEMBERSHIP_STATUS_TYPE = {
  APPROVED: "APPROVED",
  PENDING: "PENDING",
  REJECTED: "REJECTED"
};

export const NOTIFICATION_GROUP_TYPE = {
  CUSTOMER_ADMIN: "CUSTOMER_ADMIN"
};

export const LOCALE_CODE = {
  EN_US: "EN_US",
  EN_CA: "EN_CA",
  EN_GB: "EN_GB",
  EN_NZ: "EN_NZ",
  EN_ZA: "EN_ZA",
  EN_NA: "EN_NA"
};

export const DATE_UNIT = {
  MONTH: "month",
  YEAR: "year"
};

export const INPUT_TYPE = {
  TEXT: "TEXT",
  SELECT: "SELECT"
};

export const FILTER_TYPE = {
  RANGE: "RANGE",
  SELECT: "SELECT"
};

export const FILE_FORMAT = {
  PRINT: "PRINT",
  PDF: "PDF",
  CSV: "CSV"
};

export const REQUIRED_FIELD = "Field is required";

export const BIDDING_ERRORS = {
  NOT_ACTIVE: "Bidding is not active",
  NO_BADGE: "No badge found",
  CONSIGNOR: "You are the consignor",
  HIGH_BIDDER: "You are the high bidder",
  CREDIT_LIMIT_EXCEEDED: "Credit limit exceeded",
  MAX_BID_SET: "Your max bid is set",
  POINTS_LIMIT_EXCEEDED: "Points limit exceeded",
  SOLD_WINNER: "Sold to you",
  SOLD_LOSER: "Did not sell to you",
  NO_SELL: "Did not sell"
};

export const REGISTRATION_PATH = {
  REGISTER: "/register",
  AUCTION_ACCESS: "/register/auctionAccess",
  DEALERSHIP_DETAILS: "/register/dealershipDetails",
  USER: "/register/user",
  MEMBERSHIP: "/register/membership"
};

export const REGISTRATION_UI_TYPE = {
  PUBLIC_BIDDER: "publicBidder",
  DEALERSHIP_WITH_AUCTION_ACCESS_ID: "dealerWithAuctionAccessId",
  DEALERSHIP_WITH_DEALERSHIP_DETAILS: "dealerWithDealershipDetails"
};

export const INTEGRATION_TYPE = {
  FLAMS: "FLAMS",
  CUBED: "CUBED"
};

export const SALE_ACTION_LOG_TYPE = {
  LOGIN: "login",
  LOGOUT: "logout",
  CHAT: "chat",
  VIEW: "view"
};

export const ORIGIN = {
  FLOOR: "FLOOR",
  INTERNET: "INTERNET"
};

export const UI_SCHEMA_TYPE = {
  FILTER_INVENTORY: "FILTER_INVENTORY",
  REPORT_INVENTORY: "REPORT_INVENTORY"
};

export const EXTERNAL_URL = {
  RELEASE_NOTES: "https://www.xcira.com/release-notes"
};

export const ENTITY_TYPE = {
  COMPANY: "COMPANY",
  EVENT: "EVENT"
};

export const COMPANY_DEFAULT_MEDIA = {
  EVENT: "Event_Image_Default",
  LOGO: "Favicon_Logo",
  LOGIN_PAGE_IMG: "Homepage_Logo"
};

export const TWO_FACTOR_AUTH_ERRORS = {
  REQUIRES_TWO_FACTOR_VERIFICATION: "Requires Two Factor Verification",
  REQUIRES_DEVICE_VERIFICATION: "Requires Device Verification",
  REQUIRES_EMAIL_VERIFICATION: "Requires Email Verification"
};
