import { useEffect } from "react";
import { useSelector, useDispatch } from "utils/StoreUtil";
import JSONUtil from "utils/JSONUtil";
import { logoutAction } from "actions/userActions";

const COOKIE_STORE = "cookieStore";
const COOKIE_STORE_CHANGE = "change";
const VISIBILITY_CHANGE = "visibilitychange";

//cookie change event happens when a cookie is added, removed, or changed
const useListenToCookieChange = (cookieName: string) => {
  const dispatch = useDispatch();

  const userId = useSelector(state => state.user.user?.id);
  const isGuest = useSelector(state => state.user.user?.guest);

  useEffect(() => {
    const handleCookieChange = (event: any) => {
      const changedCookie = event.changed[0];

      if (!changedCookie) return;
      if (changedCookie.name !== cookieName) return;
      if (isGuest) return;

      const cookieUserId = JSONUtil.getValueFromJSON(changedCookie.value, "userId");

      if (!cookieUserId) return;

      if (!userId || (userId && cookieUserId !== userId)) {
        dispatch(logoutAction());
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        if (COOKIE_STORE in window) {
          (window as any).cookieStore.addEventListener(COOKIE_STORE_CHANGE, handleCookieChange);
        } else {
          console.warn("cookieStore API is not supported in this browser.");
        }
      } else {
        if (COOKIE_STORE in window) {
          (window as any).cookieStore.removeEventListener(COOKIE_STORE_CHANGE, handleCookieChange);
        }
      }
    };

    if (COOKIE_STORE in window) {
      (window as any).cookieStore.addEventListener(COOKIE_STORE_CHANGE, handleCookieChange);
    } else {
      console.warn("cookieStore API is not supported in this browser.");
    }

    document.addEventListener(VISIBILITY_CHANGE, handleVisibilityChange);
    handleVisibilityChange();

    return () => {
      document.removeEventListener(VISIBILITY_CHANGE, handleVisibilityChange);
      if (COOKIE_STORE in window) {
        (window as any).cookieStore.removeEventListener(COOKIE_STORE_CHANGE, handleCookieChange);
      }
    };
  }, [dispatch, userId, cookieName, isGuest]);
};

export default useListenToCookieChange;
