import { memo, useEffect } from "react";
import "./App.css";
import Main from "./views/Main";
import Navbar from "./components/navbar/Navbar";
import {
  CssBaseline
  // useMediaQuery
} from "@mui/material";
import { Router } from "react-router-dom";
import { useDispatch, useSelector } from "utils/StoreUtil";
import { history } from "./store";

import { getCompanyById } from "./reducers/companies";
import { authenticate, getPermissions } from "actions/userActions";
import {
  getCompanies,
  fetchCompanyConfigurations,
  fetchCompanyLocations
} from "actions/companyActions";
import { fetchCurrencies } from "actions/currencyActions";
import { fetchLanguageMap } from "actions/languageActions";
import { getApplicationConfiguration } from "actions/appConfigActions";
import { fetchAmsConfigurations } from "actions/amsActions";
import { fetchAssetSchemas, fetchUISchema } from "actions/schemaActions";
import { sync } from "actions/timeActions";
import { useViewportMeta } from "hooks/useViewportMeta";
import GoNative from "services/gonative";

import ThemeProvider from "./components/ThemeProvider";
import IntlProvider from "components/IntlProvider";
import ErrorBoundary from "./components/ErrorBoundary";
import CookieMessage from "./components/CookieMessage";
import Notifications from "./components/Notifications";
import Banner from "./components/Banner";
import Footer from "components/Footer";
import Dialog from "components/dialog";
import Drawer from "components/drawer";
import LoginPrompt from "components/LoginPrompt";
import UnsavedChangesGuard from "components/UnsavedChangesGuard";
import ExternalLoginGuard from "components/login/ExternalLoginGuard";
import StockWaveIntegrationInitProvider from "features/stockwave/StockWaveIntegrationInitProvider";
import useListenToCookieChange from "hooks/app/useListenToCookieChange";

const App = props => {
  const dispatch = useDispatch();
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const isAuthorized = useSelector(state => state.user.isAuthorized);
  const companyId = useSelector(state => state.user.user.companyId);
  const companyName = useSelector(state => {
    const {
      companies: { companies }
    } = state;
    const company = getCompanyById(companies, companyId);

    return company && company.name;
  });

  // Un-comment these lines to once dark mode is ready for users

  // useEffect(() => {
  //   if (prefersDarkMode) {
  //     dispatch({ type: "SET_DARK_MODE", payload: { darkMode: true } });
  //   }
  // }, [dispatch, prefersDarkMode]);

  useViewportMeta();
  useListenToCookieChange("CompanyXD");

  useEffect(() => {
    document.title = companyName || "Cubed Community";
  }, [companyName]);

  useEffect(() => {
    async function init() {
      if (!isAuthorized) {
        await dispatch(authenticate());
      }
      await dispatch(getPermissions());
      await dispatch(getResources());
    }

    async function getResources() {
      dispatch(getApplicationConfiguration());

      dispatch(getCompanies());
      dispatch(fetchAmsConfigurations());
      dispatch(fetchCurrencies());
      dispatch(fetchCompanyConfigurations());
      dispatch(fetchCompanyLocations());
      dispatch(sync());
    }
    init();
  }, [isAuthorized, dispatch]);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchUISchema(companyId));
    }
  }, [dispatch, companyId]);

  useEffect(() => {
    if (companyId && isAuthorized) {
      dispatch(fetchAssetSchemas());
    }
  }, [dispatch, companyId, isAuthorized]);

  const marketSegment = useSelector(state => state.config.marketSegment);
  const selectedLanguage = useSelector(state => state.language.selectedLanguage);

  useEffect(() => {
    if (marketSegment) {
      dispatch(fetchLanguageMap(selectedLanguage, marketSegment));
    }
  }, [dispatch, marketSegment, selectedLanguage]);

  return (
    <>
      <CssBaseline />
      {/* <React.StrictMode> */}
      <ThemeProvider>
        <StockWaveIntegrationInitProvider>
          <IntlProvider>
            <div className="App">
              <Router
                history={history}
                getUserConfirmation={(message, callback) => {
                  const allowTransition = window.confirm(message);
                  callback(allowTransition);
                }}
              >
                <ErrorBoundary>
                  <LoginPrompt />
                  <Dialog />
                  <Banner />
                  <Navbar />
                  <UnsavedChangesGuard>
                    <ExternalLoginGuard>
                      <Main />
                    </ExternalLoginGuard>
                  </UnsavedChangesGuard>
                  {!GoNative.IsGoNative() && <Footer />}
                  <Notifications />
                  {!GoNative.IsGoNative() && <CookieMessage />}
                  <Drawer />
                </ErrorBoundary>
              </Router>
            </div>
          </IntlProvider>
        </StockWaveIntegrationInitProvider>
      </ThemeProvider>
      {/* </React.StrictMode> */}
    </>
  );
};

export default memo(App);
