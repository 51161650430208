import { get, put, del } from "./api";

export async function signIn(email, password) {
  const { data } = await put("user/signIn", { email, password });
  return data;
}

export async function externalSignIn(token) {
  const { data } = await put("application/community/api/user/signIn", { token });
  return data;
}

export async function signInXD(email, password, hostName) {
  await put(
    "user/signIn",
    { email, password },
    window.location.protocol + "//" + hostName + "/services/"
  );
}

export async function signOut() {
  const { data } = await put("user/signOut");
  return data;
}

export async function signOutXD(hostName) {
  await put("user/signOut", undefined, window.location.protocol + "//" + hostName + "/services/");
}

export async function authenticate() {
  const { data } = await get("companyUser/authenticate");
  return data;
}

export async function signInGuest() {
  const { data } = await put("user/signIn/guest");
  return data;
}

export async function getPermissions() {
  const { data } = await get("user/permission");
  return data;
}

export async function getApplicationPermissions() {
  const { data } = await get("applicationPermission");
  return data;
}

export async function resetPassword(email) {
  const { data } = await put("companyUser/resetPassword", { email });
  return data;
}

export async function resendActivationEmail(user) {
  const { data } = await put("companyUser/resendActivationEmail", { ...user });
  return data;
}

export async function changeEmail(user) {
  const { data } = await put("companyUser/changeEmail?redirect=changeEmailSuccess", user);
  return data;
}

export async function getRoles(filters, range, sorts) {
  const { data } = await get("role", filters, range, sorts);
  return data;
}

export async function getCompanyUser(companyUserId) {
  const { data } = await get(`companyUser/${companyUserId}`);
  return data;
}

export async function flushCompanyUser(userId) {
  await del(`flush/companyUser/${userId}`);
}

export async function getCompanyUserPropertiesByEmailAndPassword(email, password) {
  const { data } = await put("companyUser/properties", { email, password });
  return data;
}
