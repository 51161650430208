import {
  USER_LOGIN_ATTEMPT,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT,
  USER_GUEST_LOGIN,
  USER_UPDATE,
  USER_GET_PERMISSIONS,
  USER_SET_PERMISSIONS,
  USER_RESET_PASSWORD_ATTEMPT,
  USER_RESET_PASSWORD_FAILURE,
  USER_RESET_PASSWORD_SUCCESS,
  USER_REGISTRATION_ATTEMPT,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_FAILURE,
  USER_UPDATE_FAILED,
  USER_UPDATE_REQUEST,
  USER_SET_MEDIA,
  USER_CHANGE_EMAIL_REQUEST,
  USER_CHANGE_EMAIL_REQUEST_COMPLETE,
  USER_UPDATE_ATTEMPT,
  USER_UPDATE_RESOLUTION,
  USER_SET_LAST_DEALERSHIP_SYNC_MESSAGE
} from "./actionTypes";
import { showNotification, dismiss, showError } from "./notificationActions";
import * as authRequests from "api/authRequests";
import {
  resetPassword,
  resendActivationEmail as resendActivationEmailFromApi,
  changeEmail as changeEmailApi
} from "api/authRequests";
import { getMedia, getUserMedia, getUsersMedia } from "api/mediaRequests";
import { getHostNames } from "reducers/config";
import { history } from "store";
import { getDealerships } from "actions/attendanceActions";
import { rehydrateServerAppState } from "actions/serverAppStateActions";
import { isDuplicateUserError } from "reducers/user";
import { openDialogWithProps } from "./dialogActions";
import {
  getCompanyUsers,
  registerCompanyUser,
  requestTwoFactorVerificationCode,
  verifyTwoFactorVerificationCode
} from "api/userRequests";
import { createTermFilter, createQueryFilter } from "utils/SearchUtil";
import { fetchUsers, fetchUsersComplete } from "reducers/users";
import { getRange } from "utils/Pagination";
import { setMedias } from "./adminActions";
import { updateCompanyUser, updateUser as updateUserApi } from "api/userRequests";
import { DIALOG_CONTENT_TYPE, TWO_FACTOR_AUTH_ERRORS } from "utils/constants";
import GoNative from "services/gonative";
import { Severity } from "types/alert";
import TimerUtil from "utils/TimerUtil";

export function loginAttempt(email, password) {
  return async (dispatch, getState) => {
    dispatch({ type: USER_LOGIN_ATTEMPT });
    try {
      let user = await authRequests.signIn(email, password);
      await Promise.all(
        getHostNames(getState()).map(hostName => authRequests.signInXD(email, password, hostName))
      ).catch(error => null);
      await dispatch(getPermissions());
      await dispatch(loginSuccess(user));
      dispatch(showNotification(`Logged in as ${user.name}`, Severity.info));
    } catch (error) {
      if (error.response?.data === "Password Doesn't Match") {
        dispatch(dismiss());
        dispatch(openDialogWithProps("Password Help", {}));
      }
      if (error.response?.data === TWO_FACTOR_AUTH_ERRORS.REQUIRES_EMAIL_VERIFICATION) {
        dispatch(dismiss());
        dispatch(openDialogWithProps(DIALOG_CONTENT_TYPE.REQUIRES_EMAIL_VERIFICATION, { email }));
      }

      if (
        error.response?.data === TWO_FACTOR_AUTH_ERRORS.REQUIRES_TWO_FACTOR_VERIFICATION ||
        error.response?.data === TWO_FACTOR_AUTH_ERRORS.REQUIRES_DEVICE_VERIFICATION
      ) {
        dispatch(dismiss());
        const user = await authRequests.getCompanyUserPropertiesByEmailAndPassword(email, password);
        let dialogType = DIALOG_CONTENT_TYPE.REQUIRES_TWO_FACTOR_VERIFICATION;

        if (error.response?.data === TWO_FACTOR_AUTH_ERRORS.REQUIRES_DEVICE_VERIFICATION) {
          dialogType = DIALOG_CONTENT_TYPE.VALIDATE_DEVICE_VERIFICATION_CODE;
          await requestTwoFactorVerificationCode(user);
        }

        dispatch(
          openDialogWithProps(dialogType, {
            email,
            mobilePhone: user.fields.mobilePhone,
            userId: user.id,
            countryDialingCode: user.fields.countryDialingCode,
            countryDialingCountry: user.fields.countryDialingCountry,
            country: user.fields.country,
            twoFactorVerified: user.deliveryPreference.twoFactorVerified
          })
        );
      }

      dispatch(loginFailure(error));
    }
  };
}

export function loginWithTwoFactorVerificationCode(user, verificationCode) {
  return async dispatch => {
    dispatch({ type: USER_LOGIN_ATTEMPT });
    try {
      await verifyTwoFactorVerificationCode(user, verificationCode);
      const authUser = await authRequests.authenticate();
      await dispatch(getPermissions());
      await dispatch(loginSuccess(authUser));
      dispatch(showNotification(`Logged in as ${authUser.name}`, Severity.info));
    } catch (error) {
      dispatch(loginFailure(error));
      dispatch(dismiss());
      throw error;
    }
  };
}

export function externalLoginAttempt(token) {
  return async dispatch => {
    dispatch({ type: USER_LOGIN_ATTEMPT });
    try {
      let user = await authRequests.externalSignIn(token);

      await dispatch(getPermissions());
      await dispatch(loginSuccess(user));

      dispatch(showNotification(`Logged in as ${user.name}`, Severity.info));
    } catch (error) {
      dispatch(loginFailure(error));
    }
  };
}

function loginSuccess(user) {
  GoNative.updateUserRegisteredDevice(user.email);

  return async dispatch => {
    await dispatch(rehydrateServerAppState());
    const media = await getUserMedia(user);

    const userPushDeliveryPreference = user?.deliveryPreference?.push;

    if (
      userPushDeliveryPreference != null &&
      !userPushDeliveryPreference &&
      GoNative.IsGoNative() &&
      GoNative.appHasOneSignal()
    ) {
      user = await updateUserDeliveryPreference(user);
    }

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: {
        user,
        media
      }
    });
  };
}

async function updateUserDeliveryPreference(user) {
  try {
    const updatedUser = { ...user, deliveryPreference: { ...user.deliveryPreference, push: true } };

    await updateUserApi(updatedUser);

    return updatedUser;
  } catch (error) {
    return user;
  }
}

export function setUserLastDealershipSyncMessage(lastDealershipSyncMessage) {
  return {
    type: USER_SET_LAST_DEALERSHIP_SYNC_MESSAGE,
    payload: lastDealershipSyncMessage
  };
}

export function loginFailure(error) {
  return {
    type: USER_LOGIN_FAILURE,
    payload: {
      error
    }
  };
}

export function logout() {
  GoNative.removeUserRegisteredDevice();

  return async (dispatch, getState) => {
    await authRequests.signOut();
    await Promise.all(
      getHostNames(getState()).map(hostName => authRequests.signOutXD(hostName))
    ).catch(error => null);
    dispatch(logoutAction());

    dispatch(showNotification("Logged out", Severity.info));

    const { useExternalLogin, externalLoginRedirectUrl } = getState().config;

    if (useExternalLogin && externalLoginRedirectUrl) {
      await TimerUtil.delay(500);

      window.location.href = externalLoginRedirectUrl;
    } else {
      history.push("/login");
    }
  };
}

export function logoutAction() {
  return {
    type: USER_LOGOUT
  };
}

export function authenticate() {
  return async (dispatch, getState) => {
    let auth;
    try {
      auth = await authRequests.authenticate();
      if (!auth.guest) {
        await dispatch(loginSuccess(auth));
      } else {
        dispatch(loginGuest(auth));
      }
    } catch (error) {
      await authRequests.signOut();
      await dispatch(logoutAction());
      auth = await authRequests.signInGuest();
      dispatch(loginGuest(auth));
    }
  };
}

export function loginGuest(user) {
  return {
    type: USER_GUEST_LOGIN,
    payload: {
      user
    }
  };
}

export function updateActiveStatus(isUserActive = false) {
  return async (dispatch, getState) => {
    dispatch({ type: USER_UPDATE_ATTEMPT });

    try {
      const {
        userDealershipDetails: { details: user }
      } = getState();

      const updatedUser = { ...user, active: !isUserActive };

      await updateCompanyUser(updatedUser);

      dispatch({ type: USER_UPDATE_RESOLUTION, payload: { user: updatedUser } });
      dispatch(showNotification(isUserActive ? "Deactivated" : "Activated", Severity.info));
    } catch (error) {
      dispatch({ type: USER_UPDATE_RESOLUTION });
    }
  };
}

export function adminUpdateUser(user) {
  return async dispatch => {
    dispatch({ type: USER_UPDATE_ATTEMPT });

    try {
      await updateCompanyUser(user);

      scrubUser(user);

      dispatch({ type: USER_UPDATE_RESOLUTION, payload: { user } });
      dispatch(showNotification("Updated", Severity.success));
    } catch (error) {
      dispatch({ type: USER_UPDATE_RESOLUTION });
    }
  };
}

function scrubUser(user) {
  if (Boolean(user) && typeof user === "object") {
    user.password = null;
  }
}

export function updateUser(user, runScript = true, showMessage = true) {
  return async (dispatch, getState) => {
    if (showMessage) {
      dispatch(showNotification("Saving Changes", Severity.info));
    }

    try {
      dispatch(userUpdateRequest());

      const currentUser = runScript
        ? getState().user.user
        : await authRequests.getCompanyUser(getState().user.user.id);
      const updatedUser = {
        ...currentUser,
        ...user,
        // send original company id
        companyId: currentUser.companyId
      };

      const updateUserEndpoint = runScript ? registerCompanyUser : updateUserApi;
      const res = await updateUserEndpoint(updatedUser);
      const { disclaimerCheckbox, ...updatedUserInstance } = updatedUser;

      dispatch(userUpdate(updatedUserInstance));

      if (
        updatedUser.fields.auctionAccessId &&
        updatedUser.fields.auctionAccessId !== currentUser.fields.auctionAccessId
      ) {
        dispatch(getDealerships(true));
      }

      if (showMessage) {
        dispatch(showNotification("Profile updated", Severity.success));
      }

      return res;
    } catch (error) {
      dispatch(userUpdateFailed());
      throw error;
    }
  };
}

export function userUpdate(user) {
  return {
    type: USER_UPDATE,
    payload: {
      user
    }
  };
}

function userUpdateRequest() {
  return {
    type: USER_UPDATE_REQUEST,
    payload: {}
  };
}

function userUpdateFailed() {
  return {
    type: USER_UPDATE_FAILED,
    payload: {}
  };
}

export function registerUser(user, membershipFields) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: USER_REGISTRATION_ATTEMPT });

      const res = await registerCompanyUser(user, membershipFields);

      dispatch({ type: USER_REGISTRATION_SUCCESS });
      dispatch(openDialogWithProps(DIALOG_CONTENT_TYPE.REGISTRATION_CONFIRMATION));
      return res;
    } catch (error) {
      dispatch({ type: USER_REGISTRATION_FAILURE });
      if (isDuplicateUserError(error)) {
        dispatch(dismiss());
        dispatch(
          openDialogWithProps(DIALOG_CONTENT_TYPE.RESEND_ACTIVATION_EMAIL, { email: user.email })
        );
      }
    }
  };
}

export function getPermissions() {
  return async dispatch => {
    dispatch({ type: USER_GET_PERMISSIONS });
    const [permissions, appPermissions] = await Promise.all([
      authRequests.getPermissions(),
      authRequests.getApplicationPermissions()
    ]);
    dispatch({ type: USER_SET_PERMISSIONS, payload: { permissions, appPermissions } });
  };
}

export function resetPasswordAttempt(email) {
  return async dispatch => {
    dispatch({ type: USER_RESET_PASSWORD_ATTEMPT });
    try {
      const res = await resetPassword(email);
      dispatch(resetPasswordSuccess());
      dispatch(showNotification(`A link has been sent to ${email}.`, Severity.info));
      return res;
    } catch (error) {
      dispatch(resetPasswordFailure());
    }
  };
}

function resetPasswordFailure() {
  return {
    type: USER_RESET_PASSWORD_FAILURE
  };
}

function resetPasswordSuccess() {
  return {
    type: USER_RESET_PASSWORD_SUCCESS
  };
}

export function resendActivationEmail(email) {
  return async dispatch => {
    await resendActivationEmailFromApi({ email });
    dispatch(showNotification("Activation email re-sent", Severity.info));
  };
}

export function setUserMedia(media) {
  return async (dispatch, getState) => {
    const userMedia = getState().user.media;
    if (!userMedia?.urls) {
      if (media?.entityType && media?.entityId) {
        media = await getMedia(media.entityType, media.entityId);
      }
    }
    dispatch({
      type: USER_SET_MEDIA,
      payload: {
        media
      }
    });
  };
}

export function changeEmail(email) {
  return async (dispatch, getState) => {
    try {
      const user = getState().user.user;

      dispatch({ type: USER_CHANGE_EMAIL_REQUEST });

      await changeEmailApi({ ...user, email });

      dispatch(showNotification(`Confirmation email sent to ${user.email}`, Severity.info));
    } finally {
      dispatch({ type: USER_CHANGE_EMAIL_REQUEST_COMPLETE });
    }
  };
}

export function getAdminUsers(roleId, query) {
  return async (dispatch, getState) => {
    const {
      users: { page, perPage, sort }
    } = getState();

    dispatch(fetchUsers());

    try {
      const filters = [createTermFilter("roleIds", roleId)];

      if (query) {
        filters.push(getUserQueryFilter(query));
      }

      const { data: admins, total } = await getCompanyUsers(filters, getRange(page, perPage), [
        sort
      ]);

      const medias = await getUsersMedia(admins.map(admin => admin.id));

      dispatch(fetchUsersComplete(admins, total));
      dispatch(setMedias(medias));
    } catch (error) {
      dispatch(showError(error.response?.data || error.message));
      dispatch(fetchUsersComplete([], 0));
    }
  };
}

export function getUserQueryFilter(query) {
  const formattedQuery = `*${query}*`;
  const fields = ["email^10", "name^10", "firstName^5", "lastName^5"];

  return createQueryFilter(formattedQuery, fields);
}
