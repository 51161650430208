import { useEffect } from "react";
import useParseSearchQuery from "hooks/useParseSearchQueries";
import { useDispatch } from "utils/StoreUtil";
import { openDialogWithProps } from "actions/dialogActions";
import { DIALOG_CONTENT_TYPE } from "utils/constants";

const useEmailVerifiedDialog = () => {
  const dispatch = useDispatch();

  const [emailVerified] = useParseSearchQuery("emailVerified");

  useEffect(() => {
    if (emailVerified === "true") {
      dispatch(openDialogWithProps(DIALOG_CONTENT_TYPE.EMAIL_VERIFIED, {}));
    }
  }, [emailVerified, dispatch]);
};

export default useEmailVerifiedDialog;
